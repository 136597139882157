export const DEVICE_BLOCKS_LAYOUT = 'deviceBlocks';
export const ANALYTES_TABLE_LAYOUT = 'analytesTableLayout';
export const CURRENT_LAYOUT = ANALYTES_TABLE_LAYOUT;

export const U120 = {
  key: 'u120',
  label: 'U120 Smart',
};

export const OPTIMA = {
  key: 'optima',
  label: 'URiSCAN Optima',
  printLabel: 'URiSCAN',
};

export const UTI_US_STUDY = {
  key: 'utiUs',
  name: 'uti-us',
  label: 'Velieve U.S.',
  protocolNumber: 'CTP - Velieve U.S – MC - 02',
};

export const ACR_US_STUDY = {
  key: 'acrUs',
  name: 'acr-us',
  label: 'ACR | U.S.',
  protocolNumber: 'ACR-US-MCU-02',
};

export const DIP_US_STUDY = {
  key: 'dipUs',
  name: 'dip-us',
  label: 'Minuteful 10',
  protocolNumber: 'MNT-10_US-MCU-01',
};

export const osKeyToName = {
  ios: 'iOS',
  android: 'Android',
  default: '',
};

export const acrResultToString = {
  normal: 'Normal',
  abnormal: 'Abnormal',
  habnormal: 'High Abnormal',
  default: '',
};

export const utiResultToString = {
  blood: {
    '(-)': 'Negative',
    '(+-)': '10',
    '1+': '25',
    '2+': '80',
    '3+': '200',
  },
  leukocyte: {
    '(-)': 'Negative',
    '(+-)': '15',
    '1+': '70',
    '2+': '125',
    '3+': '500',
  },
  nitrites: {
    '(-)': 'Negative',
    '(+)': 'Positive',
  },
};

export const dipResultToString = {
  urobilinogen: {
    '(+-)': 'Normal',
  },
};

export const colors = {
  /* ACR */
  albumin: {
    10: '#cbdab8',
    30: '#afd5b9',
    80: '#95d0bb',
    150: '#68c8c8',
  },
  creatinine: {
    10: '#bda12b',
    50: '#c3b869',
    100: '#acb675',
    200: '#789761',
    300: '#577846',
  },
  acr: {
    normal: '#1f8c6a',
    abnormal: '#ff6b6b',
    habnormal: '#ff6b6b',
  },
  /* UTI  + DIP */
  blood: {
    '-': '#f2af24',
    '(-)': '#f2af24',
    '+-': '#d8b037',
    '(+-)': '#d8b037',
    '1+': '#b1a240',
    '2+': '#7d8249',
    '3+': '#5a6347',
    3: '#5a6347',
  },
  nitrites: { '-': '#f6daa7', '(-)': '#f6daa7', '+': '#ffd3a7', '(+)': '#ffd3a7' },
  leukocyte: {
    '-': '#e5c98f',
    '(-)': '#e5c98f',
    '+-': '#dfc191',
    '(+-)': '#dfc191',
    '1+': '#c8ac8c',
    '2+': '#a77e80',
    '3+': '#906c7c',
  },
  /* DIP */
  glucose: {
    '(-)': '#9cb88f',
    '(+-)': '#a2bb74',
    '1+': '#9aa74f',
    '2+': '#a98b3c',
    '3+': '#9b6c2d',
    '4+': '#986a3f',
  },
  bilirubin: {
    '(-)': '#f3c982',
    '1+': '#efc082',
    '2+': '#d6b07d',
    '3+': '#c9a07c',
  },
  ketones: {
    '(-)': '#deaa7a',
    '(+-)': '#e89e79',
    '1+': '#dc7d71',
    '2+': '#c5655d',
    '3+': '#95484d',
  },
  specific_gravity: {
    '1.000': '#325351',
    1: '#325351',
    1.005: '#53694e',
    '1.010': '#767a46',
    1.015: '#948334',
    '1.020': '#a68d2e',
    1.025: '#ac8a2b',
    '1.030': '#ce9934',
  },
  ph: {
    '5.0': '#f08134',
    5: '#f08134',
    5.5: '#f08f38',
    '6.0': '#f19e3c',
    6: '#f19e3c',
    6.5: '#e5ae3d',
    '7.0': '#c9ac4f',
    7: '#c9ac4f',
    7.5: '#9b9842',
    '8.0': '#6b9266',
    8: '#6b9266',
    8.5: '#327a71',
    '9.0': '#327a71',
    9: '#327a71',
  },
  proteins: {
    '(-)': '#dfd166',
    '(+-)': '#cac75e',
    '1+': '#afb668',
    '2+': '#9ab17d',
    '3+': '#7fa483',
    '4+': '#6D8A70',
  },
  urobilinogen: {
    '(-)': '#ffbd7d',
    '(+-)': '#faa77a',
    '1+': '#ef9278',
    '2+': '#f28675',
    '3+': '#ea7077',
    '4+': '#ef536f',
  },
  leukocytes: {
    '-': '#e5c98f',
    '(-)': '#e5c98f',
    '+-': '#dfc191',
    '(+-)': '#dfc191',
    '1+': '#c8ac8c',
    '2+': '#a77e80',
    '3+': '#906c7c',
  },
};
