/* eslint-disable import/no-mutable-exports */

// Saga constants
const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
const DAEMON = '@@saga-injector/daemon';
const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// Auth0 routes
let LOGIN_PATH = 'oauth/token';
const PASSWORD_RESET_PATH = 'dbconnections/change_password';
// const LOGOUT_PATH = 'v2/logout';

// Staging constants
let BASE_URL;
let OLD_BASE_URL;
let SOCKET_URL;
let AUTH_AUDIENCE;
let AUTH_CLIENT_ID;

const AUTH_URL = 'https://healthyio.auth0.com/';

switch (process.env.NODE_ENV) {
  case 'production':
    switch (process.env.HEALTHY_ENV) {
      case 'prod':
        // Production constants
        // https://research.healthy.io
        BASE_URL = 'https://web-service.re.healthy.io/clinicianPortal/';
        LOGIN_PATH = 'login';
        break;

      case 'staging':
        // Staging constants
        // https://research-staging.healthy.io
        BASE_URL =
          'https://web-service.rehealthy-develop.be-staging-uk.healthy.io/clinicianPortal/';
        LOGIN_PATH = 'login';
        break;

      default:
        throw new Error(`HEALTHY_ENV not recognized: ${process.env.HEALTHY_ENV}`);
    }
    break;
  case 'development':
  case 'test':
    BASE_URL =
      'https://web-service.rehealthy-develop.be-staging-uk.healthy.io/clinicianPortal/';
    LOGIN_PATH = 'login';
    break;
  default:
    throw new Error(`NODE_ENV not recognized: ${process.env.NODE_ENV}`);
}

export {
  BASE_URL,
  OLD_BASE_URL,
  SOCKET_URL,
  AUTH_URL,
  AUTH_AUDIENCE,
  AUTH_CLIENT_ID,
  LOGIN_PATH,
  PASSWORD_RESET_PATH,
  RESTART_ON_REMOUNT,
  DAEMON,
  ONCE_TILL_UNMOUNT,
};
