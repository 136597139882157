/*
 *
 * LogOutButton
 *
 */

import PropTypes from 'prop-types';

import { triggerLogoutEvent } from './AuthSynchronizer';

function LogOutButton({ children, className }) {
  return (
    <button type="button" className={className ?? ''} onClick={triggerLogoutEvent}>
      {children}
    </button>
  );
}

LogOutButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default LogOutButton;
