/*
 *
 * RehealthyTestsPage actions
 *
 */

import {
  FETCH_TESTS,
  FETCH_TESTS_SUCCESS,
  FETCH_TESTS_ERROR,
  UPDATE_PAGE_NUMBER,
  UPDATE_PER_PAGE,
  UPDATE_SEARCH_QUERY,
  UPDATE_SEARCH_DATES,
  UPDATE_SEARCH_HOURS,
  TOGGLE_SEARCH_MODE,
  TOGGLE_QUALITY_CONTROL,
} from './constants';

export function fetchTests() {
  return {
    type: FETCH_TESTS,
  };
}

export function fetchTestsSuccess(response) {
  return {
    response,
    type: FETCH_TESTS_SUCCESS,
  };
}

export function fetchTestsError() {
  return {
    type: FETCH_TESTS_ERROR,
  };
}

export function updatePageNumber(pageNumber) {
  return {
    pageNumber,
    type: UPDATE_PAGE_NUMBER,
  };
}

export function updatePerPage(perPage) {
  return {
    perPage,
    type: UPDATE_PER_PAGE,
  };
}

export function updateSearchQuery(searchQuery) {
  return {
    searchQuery,
    type: UPDATE_SEARCH_QUERY,
  };
}

export function updateSearchDates(startDate, endDate) {
  return {
    startDate,
    endDate,
    type: UPDATE_SEARCH_DATES,
  };
}

export function updateSearchHours(searchHours) {
  return {
    searchHours,
    type: UPDATE_SEARCH_HOURS,
  };
}

export function toggleSearchMode(searchMode) {
  return {
    searchMode,
    type: TOGGLE_SEARCH_MODE,
  };
}

export function toggleQualityControl(qualityControl) {
  return {
    qualityControl,
    type: TOGGLE_QUALITY_CONTROL,
  };
}
