import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  updateUsername,
  updatePassword,
  fetchAuthData,
} from 'containers/AuthProvider/actions';
import makeSelectAuth from 'containers/AuthProvider/selectors';

import LoginLayout from 'components/LoginLayout';
import LoginForm from 'components/LoginForm';

export class LoginPage extends Component {
  dispatchUpdateUsername = (e) => {
    this.props.dispatch(updateUsername(e.target.value));
  };

  dispatchUpdatePassword = (e) => {
    this.props.dispatch(updatePassword(e.target.value));
  };

  fetchAuthData = (e) => {
    e.preventDefault();
    this.props.dispatch(fetchAuthData(new Date()));
  };

  render() {
    return (
      <LoginLayout title="Sign In">
        <LoginForm
          username={this.props.auth.username}
          password={this.props.auth.password}
          onUsernameChange={this.dispatchUpdateUsername}
          onPasswordChange={this.dispatchUpdatePassword}
          onSubmit={this.fetchAuthData}
          loading={this.props.auth.loading}
          error={this.props.auth.error}
        />
      </LoginLayout>
    );
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
