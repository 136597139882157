// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._2MWaXG-r {\n  position: absolute;\n  padding-bottom: 10vh;\n  top: 80px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 560px;\n}\ndiv._3p7ocCwa {\n  font-size: 42px;\n  margin-bottom: 50px;\n  font-weight: 600;\n  color: #3275d7;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginLayout": "_2MWaXG-r",
	"title": "_3p7ocCwa"
};
export default ___CSS_LOADER_EXPORT___;
