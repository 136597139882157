import { Component } from 'react';
import { ReactReduxContext } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ key, reducer }) =>
  (WrappedComponent) => {
    const { displayName, name } = WrappedComponent;
    let componentName = 'Component';
    if (displayName) componentName = displayName;
    else if (name) componentName = name;

    class ReducerInjector extends Component {
      static WrappedComponent = WrappedComponent;

      static displayName = `withReducer(${componentName})`;

      static contextType = ReactReduxContext;

      UNSAFE_componentWillMount() {
        const { injectReducer } = this.injectors;

        injectReducer(key, reducer);
      }

      injectors = getInjectors(this.context.store);

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };
