// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.khNP1ktU {\n  position: relative;\n  width: 400px;\n}\n    div.khNP1ktU form > * {\n      margin-bottom: 30px;\n    }\n    div.khNP1ktU form button {\n      margin-top: 40px;\n      margin-bottom: 0;\n      margin-left: -20px;\n    }\n    div.khNP1ktU div._11U5Ep_G {\n    position: absolute;\n    font-size: 13px;\n    top: 100%;\n    left: 0;\n    right: 0;\n    text-align: center\n  }\n    div.khNP1ktU div._11U5Ep_G > * {\n      margin-top: 20px;\n    }\n    div.khNP1ktU div._11U5Ep_G a.R7gse9Kp {\n      display: inline-block;\n      text-decoration: none;\n      color: #333;\n    }\n    div.khNP1ktU div._11U5Ep_G div.l-Btx-tr {\n      color: #f15958;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": "khNP1ktU",
	"absolute": "_11U5Ep_G",
	"pwlink": "R7gse9Kp",
	"error": "l-Btx-tr"
};
export default ___CSS_LOADER_EXPORT___;
