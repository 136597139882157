import jwtDecode from 'jwt-decode';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { authRequestHandler } from 'utils/network';
import { saveAuthDataToStorage } from 'utils/authData';
import {
  LOGIN_PATH,
  // LOGOUT_PATH,
  AUTH_AUDIENCE,
  AUTH_CLIENT_ID,
} from 'utils/constants';

import {
  FETCH_AUTH_DATA,
  // INVALIDATE_AUTH_DATA,
} from './constants';
import { fetchAuthDataSuccess, fetchAuthDataError } from './actions';
import { makeSelectUsername, makeSelectPassword } from './selectors';
import { triggerCrossTabLogin } from './AuthSynchronizer';

/**
 * Auth data fetching request/response handler
 */
export function* fetchAuthData() {
  const username = yield select(makeSelectUsername());
  const password = yield select(makeSelectPassword());

  const options = {
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      grant_type: 'password',
      audience: AUTH_AUDIENCE,
      client_id: AUTH_CLIENT_ID,
      scope: 'openid',
    }),
  };
  try {
    const response = yield call(authRequestHandler, LOGIN_PATH, options);
    const decoded = jwtDecode(response.accessToken);
    const authToken = response.accessToken;
    const idToken = response.accessToken;
    const authTokenExpireTime = decoded.expiresIn * 1000;

    yield call(saveAuthDataToStorage, {
      authToken,
      authTokenExpireTime,
      username,
      idToken,
    });
    yield call(triggerCrossTabLogin, {
      authToken,
      authTokenExpireTime,
      username,
      idToken,
    });

    yield put(fetchAuthDataSuccess(authToken, authTokenExpireTime, username, idToken));
  } catch (err) {
    yield put(fetchAuthDataError());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* authData() {
  // Watches for FETCH_AUTH_DATA actions and calls fetchAuthData when one comes in.
  yield takeLatest(FETCH_AUTH_DATA, fetchAuthData);
}
