import produce from 'immer';

import { getAuthDataFromStorage } from 'utils/authData';
import { getFlavors } from 'utils/flavors';

import {
  UPDATE_USERNAME,
  UPDATE_PASSWORD,
  FETCH_AUTH_DATA,
  FETCH_AUTH_DATA_SUCCESS,
  FETCH_AUTH_DATA_ERROR,
  CLEAR_AUTH_DATA,
  INVALIDATE_AUTH_DATA,
  CLEAR_LOGIN_FORM,
} from './constants';

const { authToken, authTokenExpireTime, username, idToken } = getAuthDataFromStorage();
const flavors = getFlavors(idToken);

export const initialState = {
  authToken,
  authTokenExpireTime,
  username,
  flavors,
  password: '',
  loading: false,
  error: false,
};

const authProviderReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_USERNAME:
      draft.error = false;
      draft.username = action.username;
      break;
    case UPDATE_PASSWORD:
      draft.error = false;
      draft.password = action.password;
      break;
    case FETCH_AUTH_DATA:
      draft.error = false;
      draft.loading = true;
      break;
    case FETCH_AUTH_DATA_SUCCESS:
      draft.username = action.username;
      draft.password = '';
      draft.authToken = action.authToken;
      draft.authTokenExpireTime = action.authTokenExpireTime;
      draft.flavors = getFlavors(action.idToken);
      draft.loading = false;
      break;
    case FETCH_AUTH_DATA_ERROR:
      draft.password = '';
      draft.error = true;
      draft.loading = false;
      break;
    case CLEAR_AUTH_DATA:
      draft.username = '';
      draft.authToken = '';
      draft.authTokenExpireTime = null;
      draft.flavors = getFlavors('');
      break;
    case INVALIDATE_AUTH_DATA:
      Object.keys(initialState).forEach((k) => {
        draft[k] = initialState[k];
      });
      break;
    case CLEAR_LOGIN_FORM:
      draft.username = '';
      draft.password = '';
      draft.error = false;
      draft.loading = false;
      break;
  }
}, initialState);

export default authProviderReducer;
