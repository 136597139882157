import { Component } from 'react';
import { ReactReduxContext } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './sagaInjectors';

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 * @param {array} [args] Arguments passed to the saga once called
 * By default your saga will receive
 *   - component props
 *   - action
 * If defined, the saga will receive those args instead of the component props
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ key, saga, mode, args }) =>
  (WrappedComponent) => {
    const { displayName, name } = WrappedComponent;
    let componentName = 'Component';
    if (displayName) componentName = displayName;
    else if (name) componentName = name;

    class InjectSaga extends Component {
      static WrappedComponent = WrappedComponent;

      static displayName = `withSaga(${componentName})`;

      static contextType = ReactReduxContext;

      UNSAFE_componentWillMount() {
        const { injectSaga } = this.injectors;
        const injectedArgs = args ?? [this.props];

        injectSaga(key, { saga, mode }, ...injectedArgs);
      }

      // NOTE: Comment this out for running all sagas as DAEMON
      componentWillUnmount() {
        const { ejectSaga } = this.injectors;

        ejectSaga(key);
      }

      injectors = getInjectors(this.context.store);

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(InjectSaga, WrappedComponent);
  };
