/*
 *
 * AuthProvider
 *
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isBefore from 'date-fns/isBefore';
import { Route, Redirect } from 'react-router-dom';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { makeSelectLocation } from 'containers/App/selectors';

import reducer from './reducer';
import saga from './sagas';
import { makeSelectAuthToken, makeSelectAuthTokenExpireTime } from './selectors';
import routes from './routes';

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.render = routes;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      window.scrollTo(0, 0);
    }
  }

  // We check if the authToken isn't already expired. This matters on first mount in particular
  // because otherwise the page would have time to mount very briefly and send an unecessary request to the server
  isUserAuthenticated = () =>
    !!this.props.authToken && !isBefore(this.props.authTokenExpireTime, new Date());

  renderPrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        this.userIsAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );

  renderPublicRouteOnly = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        this.userIsAuthenticated ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
}

AuthProvider.propTypes = {
  authToken: PropTypes.string.isRequired,
  authTokenExpireTime: PropTypes.number,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authToken: makeSelectAuthToken(),
  authTokenExpireTime: makeSelectAuthTokenExpireTime(),
  location: makeSelectLocation(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({ key: 'auth', reducer });
const withSaga = injectSaga({ key: 'auth', saga });

export default compose(withReducer, withSaga, withConnect)(AuthProvider);
