import get from 'lodash/get';
import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectToasterDomain = (state) => get(state, 'toaster', initialState);

const makeSelectToaster = () =>
  createSelector(selectToasterDomain, (substate) => substate);

export default makeSelectToaster;
export { selectToasterDomain };
