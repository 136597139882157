import { put, call, select } from 'redux-saga/effects';
import io from 'socket.io-client/dist/socket.io.slim';

import { updateToast } from 'containers/Toaster/actions';
import { triggerLogoutEvent } from 'containers/AuthProvider/AuthSynchronizer';
import { makeSelectFlavorOldBackend } from 'containers/AuthProvider/selectors';

import { getAuthDataFromStorage } from './authData';
import { BASE_URL, OLD_BASE_URL, AUTH_URL, SOCKET_URL, LOGIN_PATH } from './constants';

// This function is also used in some sagas
// Don't modify without testing thoroughly
export function parseJSON(response) {
  return response.json();
}

/**
 * Prepares the Request object for the request function
 */
export function prepareRequest(requestUrl, options) {
  const { authToken } = getAuthDataFromStorage();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  // Only include the auth token if it exists and the user isn't signing in
  if (authToken && requestUrl.indexOf(LOGIN_PATH) === -1) {
    const token = `JWT ${authToken}`;

    headers.append('Authorization', token);
  }

  return new Request(requestUrl, {
    headers,
    ...options,
  });
}

/**
 * Requests a URL, returning a promise
 */
export function request(url, options, baseUrl) {
  const newRequest = prepareRequest(`${baseUrl}${url}`, options);
  return fetch(newRequest);
}

/**
 * Generator to handle all requests and corresponding responses
 * The baseUrl param is only meant to be used by authRequestHandler
 */
export default function* requestHandler(url, options, baseUrl = BASE_URL) {
  // If we're in the old backend and the request isn't auth-related, use the old base URLs.
  /* eslint-disable no-param-reassign */
  const flavorOldBackend = yield select(makeSelectFlavorOldBackend());
  if (flavorOldBackend && baseUrl !== AUTH_URL) baseUrl = OLD_BASE_URL;
  /* eslint-enable */

  let response = yield call(request, url, options, baseUrl, flavorOldBackend);
  // Success
  if (response.status >= 200 && response.status < 300) {
    if (
      response.headers.get('content-type') &&
      response.headers.get('content-type').indexOf('json') > -1
    ) {
      response = yield call(parseJSON, response);
    }

    // Rare cases where status is OK but response.success is false
    if (typeof response.success === 'boolean' && !response.success) {
      throw new Error('Unsuccessful request');
    }

    return response;
  }

  if (response.status === 401 && response.url.indexOf(LOGIN_PATH) === -1) {
    yield call(triggerLogoutEvent);
    yield put(
      updateToast(
        'You were signed out because you requested data that you are not authorized access to.',
        '',
        6000,
      ),
    );
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function* authRequestHandler(url, options) {
  return yield* requestHandler(url, options, BASE_URL);
}

/**
 * Returns a socket connection
 */
export const createSocket = /* istanbul ignore next */ () =>
  io(`${SOCKET_URL}dip_acon?token=${getAuthDataFromStorage().authToken}`);
