/*
 *
 * AuthProvider actions
 *
 */

import {
  UPDATE_USERNAME,
  UPDATE_PASSWORD,
  FETCH_AUTH_DATA,
  FETCH_AUTH_DATA_SUCCESS,
  FETCH_AUTH_DATA_ERROR,
  CLEAR_AUTH_DATA,
  INVALIDATE_AUTH_DATA,
  CLEAR_LOGIN_FORM,
} from './constants';

export function updateUsername(username) {
  return {
    username,
    type: UPDATE_USERNAME,
  };
}

export function updatePassword(password) {
  return {
    password,
    type: UPDATE_PASSWORD,
  };
}

export function fetchAuthData(when) {
  return {
    when,
    type: FETCH_AUTH_DATA,
  };
}

export function fetchAuthDataSuccess(authToken, authTokenExpireTime, username, idToken) {
  return {
    authToken,
    authTokenExpireTime,
    username,
    idToken,
    type: FETCH_AUTH_DATA_SUCCESS,
  };
}

export function fetchAuthDataError() {
  return { type: FETCH_AUTH_DATA_ERROR };
}

export function clearAuthData() {
  return { type: CLEAR_AUTH_DATA };
}

export function invalidateAuthData() {
  return { type: INVALIDATE_AUTH_DATA };
}

export function clearLoginForm() {
  return { type: CLEAR_LOGIN_FORM };
}
