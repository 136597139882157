import { UTI_US_STUDY, ACR_US_STUDY, DIP_US_STUDY, OPTIMA, U120 } from './constants';

export function getTestsPageHeaders(studyName) {
  switch (studyName) {
    case DIP_US_STUDY.name:
      return [
        {
          key: 'id',
          path: ['id'],
          name: 'Subject Number',
          dualColumn: false,
          center: false,
        },
        { key: 'date', path: ['date'], name: 'Date', dualColumn: false, center: false },
        {
          key: DIP_US_STUDY.key,
          path: ['columns', DIP_US_STUDY.key],
          name: DIP_US_STUDY.label,
          dualColumn: false,
          center: true,
        },
        {
          key: OPTIMA.key,
          path: ['columns', OPTIMA.key],
          name: OPTIMA.label,
          dualColumn: false,
          center: true,
        },
        { key: 'site', path: ['site'], name: 'Site', dualColumn: false, center: false },
      ];
    case ACR_US_STUDY.name:
      return [
        {
          key: 'id',
          path: ['id'],
          name: 'Subject Number',
          dualColumn: false,
          center: false,
        },
        { key: 'date', path: ['date'], name: 'Date', dualColumn: false, center: false },
        {
          key: ACR_US_STUDY.key,
          path: ['columns', ACR_US_STUDY.key],
          name: ACR_US_STUDY.label,
          dualColumn: false,
          center: true,
        },
        {
          key: OPTIMA.key,
          path: ['columns', OPTIMA.key],
          name: OPTIMA.label,
          dualColumn: false,
          center: true,
        },
        { key: 'site', path: ['site'], name: 'Site', dualColumn: false, center: false },
      ];
    case UTI_US_STUDY.name:
      return [
        {
          key: 'id',
          path: ['id'],
          name: 'Subject Number',
          dualColumn: false,
          center: false,
        },
        { key: 'date', path: ['date'], name: 'Date', dualColumn: false, center: false },
        {
          key: UTI_US_STUDY.key,
          path: ['columns', UTI_US_STUDY.key],
          name: 'Velieve',
          dualColumn: false,
          center: true,
        },
        {
          key: U120.key,
          path: ['columns', U120.key],
          name: U120.label,
          dualColumn: false,
          center: true,
        },
        { key: 'site', path: ['site'], name: 'Site', dualColumn: false, center: false },
      ];
    default:
      return [];
  }
}
