// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  @media print {\n    ._3KApnUOD {\n    display: none\n}\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toaster": "_3KApnUOD"
};
export default ___CSS_LOADER_EXPORT___;
