/**
 *
 * LoginLayout
 *
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import style from './style.scss';

class LoginLayout extends PureComponent {
  render() {
    const { title, children } = this.props;
    return (
      <div className={style.loginLayout}>
        <div>
          {title && <div className={style.title}>{title}</div>}
          {children}
        </div>
      </div>
    );
  }
}

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default LoginLayout;
