/*
 *
 * QCToggle
 *
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectRehealthyTestsPageQualityControl } from './selectors';
import { toggleQualityControl } from './actions';
import style from './style.scss';
import Clinical from './images/Clinical.svg';
import QC from './images/QC.svg';
import ClinicalInactive from './images/ClinicalInactive.svg';
import QCInactive from './images/QCInactive.svg';

export class QCToggle extends PureComponent {
  enableQualityControl = () =>
    !this.props.qualityControl && this.props.dispatch(toggleQualityControl(true));

  disableQualityControl = () =>
    this.props.qualityControl && this.props.dispatch(toggleQualityControl(false));

  render() {
    return (
      <div className={style.qctoggle}>
        <button
          type="button"
          className={this.props.qualityControl ? style.inactive : null}
          onClick={this.disableQualityControl}
        >
          <img src={!this.props.qualityControl ? Clinical : ClinicalInactive} alt="" />
          <span>Clinical Tests</span>
        </button>
        <button
          type="button"
          className={this.props.qualityControl ? null : style.inactive}
          onClick={this.enableQualityControl}
        >
          <img src={this.props.qualityControl ? QC : QCInactive} alt="" />
          <span>Quality Control</span>
        </button>
      </div>
    );
  }
}

QCToggle.propTypes = {
  dispatch: PropTypes.func.isRequired,
  qualityControl: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  qualityControl: makeSelectRehealthyTestsPageQualityControl(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QCToggle);
