/*
 *
 * RehealthyTestsPage constants
 *
 */

export const FETCH_TESTS = 'app/RehealthyTestsPage/FETCH_TESTS';
export const FETCH_TESTS_SUCCESS = 'app/RehealthyTestsPage/FETCH_TESTS_SUCCESS';
export const FETCH_TESTS_ERROR = 'app/RehealthyTestsPage/FETCH_TESTS_ERROR';
export const UPDATE_PAGE_NUMBER = 'app/RehealthyTestsPage/UPDATE_PAGE_NUMBER';
export const UPDATE_PER_PAGE = 'app/RehealthyTestsPage/UPDATE_PER_PAGE';
export const UPDATE_SEARCH_QUERY = 'app/RehealthyTestsPage/UPDATE_SEARCH_QUERY';
export const UPDATE_SEARCH_DATES = 'app/RehealthyTestsPage/UPDATE_SEARCH_DATES';
export const UPDATE_SEARCH_HOURS = 'app/RehealthyTestsPage/UPDATE_SEARCH_HOURS';
export const TOGGLE_SEARCH_MODE = 'app/RehealthyTestsPage/TOGGLE_SEARCH_MODE';
export const TOGGLE_QUALITY_CONTROL = 'app/RehealthyTestsPage/TOGGLE_QUALITY_CONTROL';
