/*
 *
 * AuthProvider constants
 *
 */

export const UPDATE_USERNAME = 'app/Auth/UPDATE_USERNAME';
export const UPDATE_PASSWORD = 'app/Auth/UPDATE_PASSWORD';
export const FETCH_AUTH_DATA = 'app/Auth/FETCH_AUTH_DATA';
export const FETCH_AUTH_DATA_SUCCESS = 'app/Auth/FETCH_AUTH_DATA_SUCCESS';
export const FETCH_AUTH_DATA_ERROR = 'app/Auth/FETCH_AUTH_DATA_ERROR';
export const CLEAR_AUTH_DATA = 'app/Auth/CLEAR_AUTH_DATA';
export const INVALIDATE_AUTH_DATA = 'app/Auth/INVALIDATE_AUTH_DATA';
export const CLEAR_LOGIN_FORM = 'app/Auth/CLEAR_LOGIN_FORM';
