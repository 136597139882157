/*
 *
 * Toaster actions
 *
 */

import { UPDATE_TOAST } from './constants';

export function updateToast(message, link, time) {
  return {
    type: UPDATE_TOAST,
    message,
    link,
    time,
  };
}
