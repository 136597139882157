import { Switch, Route } from 'react-router-dom';

import Header from 'containers/Header';
import LoginPage from 'containers/LoginPage';
import TestPage from 'containers/RehealthyTestPage/Loadable';
import TestsPage from 'containers/RehealthyTestsPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';

/* eslint-disable react/no-this-in-sfc */

function render() {
  const PrivateRoute = this.renderPrivateRoute;
  const PublicRouteOnly = this.renderPublicRouteOnly;
  this.userIsAuthenticated = this.isUserAuthenticated();
  return (
    <>
      <Header
        userIsAuthenticated={this.userIsAuthenticated}
        location={this.props.location}
      />

      <Switch location={this.props.location}>
        <PrivateRoute exact path="/" component={TestsPage} />
        <PrivateRoute exact path="/test/:kitId" component={TestPage} />

        <PublicRouteOnly exact path="/login" component={LoginPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}

export default render;
