/*
 *
 * Header
 *
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Link } from 'react-router-dom';

import LogOutButton from 'containers/AuthProvider/LogOutButton';
import { makeSelectUsername } from 'containers/AuthProvider/selectors';
import RehealthyQCToggle from 'containers/RehealthyTestsPage/QCToggle';

import Logo from 'images/logo.svg';

import SignOut from './sign-out.svg';
import style from './style.scss';

export class Header extends PureComponent {
  render() {
    return (
      <div className={style.header} role="banner">
        <div>
          <Link className={style.logo} aria-label="Home" to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <Route
          exact
          path="/"
          location={this.props.location}
          component={RehealthyQCToggle}
        />
        {this.props.userIsAuthenticated && (
          <div>
            <span className={style.user}>{this.props.username}</span>
            <LogOutButton className={style.signOut}>
              <img src={SignOut} alt="" />
              <span>Sign out</span>
            </LogOutButton>
          </div>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  username: PropTypes.string.isRequired,
  userIsAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  username: makeSelectUsername(),
});

export default connect(mapStateToProps)(Header);
