import produce from 'immer';

import {
  FETCH_TESTS,
  FETCH_TESTS_SUCCESS,
  FETCH_TESTS_ERROR,
  UPDATE_PAGE_NUMBER,
  UPDATE_PER_PAGE,
  UPDATE_SEARCH_QUERY,
  UPDATE_SEARCH_DATES,
  UPDATE_SEARCH_HOURS,
  TOGGLE_SEARCH_MODE,
  TOGGLE_QUALITY_CONTROL,
} from './constants';

export const initialState = {
  tests: [],
  page: 1,
  perPage: 25,
  count: 0,
  loading: false,
  error: false,
  searchQuery: '',
  searchHours: 'All',
  startDate: null,
  endDate: null,
  searchMode: false,
  qualityControl: false,
};

const rehealthyTestsPageReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_TESTS:
      draft.loading = true;
      break;
    case FETCH_TESTS_SUCCESS: {
      const { tests, page, perPage, count } = action.response;
      draft.tests = tests;
      draft.page = page;
      draft.perPage = perPage;
      draft.count = count;
      draft.loading = false;
      draft.error = false;
      break;
    }
    case FETCH_TESTS_ERROR:
      draft.error = true;
      draft.loading = false;
      break;
    case UPDATE_PAGE_NUMBER:
      draft.page = action.pageNumber;
      break;
    case UPDATE_PER_PAGE:
      draft.perPage = action.perPage;
      break;
    case UPDATE_SEARCH_QUERY:
      draft.searchQuery = action.searchQuery;
      break;
    case UPDATE_SEARCH_DATES:
      draft.startDate = action.startDate;
      draft.endDate = action.endDate;
      break;
    case UPDATE_SEARCH_HOURS:
      draft.searchHours = action.searchHours;
      break;
    case TOGGLE_SEARCH_MODE:
      draft.searchMode = action.searchMode;
      break;
    case TOGGLE_QUALITY_CONTROL:
      draft.qualityControl = action.qualityControl;
      break;
  }
}, initialState);

export default rehealthyTestsPageReducer;
