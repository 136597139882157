import produce from 'immer';

import { UPDATE_TOAST } from './constants';

export const initialState = {
  message: '',
  link: '',
  time: 0,
  visible: false,
};

const toasterReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_TOAST:
      draft.visible = !!action.message;
      draft.time = action.time;
      if (action.message) {
        draft.message = action.message;
        draft.link = action.link;
      }
      break;
  }
}, initialState);

export default toasterReducer;
