// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._2RTnr37f {\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  min-width: 20%;\n  width: min-content;\n  padding: 16px;\n  line-height: 1.4;\n  background-color: rgba(93, 148, 214, 0.9);\n  color: white;\n  box-shadow: 0 2px 3px 0 rgba(181, 181, 181, 0.8);\n  border-radius: 5px\n}\ndiv._2RTnr37f._3YHTqWY7 {\n    width: 370px;\n    color: #333;\n    background-color: white;\n    padding: 0;\n    overflow: hidden;\n    box-shadow: 0 2px 3px 0 rgba(150, 150, 150, 0.8);\n  }\ndiv._2RTnr37f._3YHTqWY7 > div {\n      align-items: stretch;\n      display: flex;\n    }\ndiv._2RTnr37f._3YHTqWY7 > div > div {\n        font-weight: 700;\n        padding: 15px 30px 15px 25px;\n        flex-grow: 1;\n      }\ndiv._2RTnr37f._3YHTqWY7 > div > a {\n        text-decoration: none;\n        color: white;\n        font-size: 18px;\n        background-color: #3275d7;\n        padding: 15px;\n        min-width: 120px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }\n@media print {\n  div._2RTnr37f {\n    display: none\n}\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": "_2RTnr37f",
	"linkToast": "_3YHTqWY7"
};
export default ___CSS_LOADER_EXPORT___;
