/*
 *
 * NewTextInput
 *
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import style from '../style.scss';

export class NewTextInput extends PureComponent {
  render() {
    const {
      name,
      width,
      labelText,
      labelColor,
      required,
      type,
      value,
      autoFocus,
      placeholder,
      tabIndex,
      onChange,
      maxLength,
      disabled,
      caption,
      withBorder,
    } = this.props;
    const klass = cc([
      style.newTextInput,
      {
        [style.withBorder]: withBorder,
      },
    ]);
    return (
      <label className={style.formInput} htmlFor={name} style={width ? { width } : null}>
        <div className={style.labelRow} style={labelColor ? { color: labelColor } : {}}>
          <div className={style.labelText}>{labelText}</div>
          {required ? <div className={style.required}>Required</div> : null}
        </div>
        <input
          className={klass}
          type={type ?? 'text'}
          value={value ?? ''} // Value needs an empty string to trigger controlled input as the default behavior
          id={name}
          name={name}
          autoFocus={autoFocus}
          placeholder={placeholder}
          tabIndex={tabIndex}
          onChange={onChange}
          maxLength={maxLength}
          disabled={disabled}
          ref={(input) => {
            this.input = input;
          }}
        />
        {caption && caption.length ? (
          <div className={style.caption}>{caption}</div>
        ) : null}
      </label>
    );
  }
}

NewTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
  width: PropTypes.number,
  caption: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  withBorder: PropTypes.bool,
};

export default NewTextInput;
