// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1yYd2jLY {\n  height: 80px;\n  padding: 0 50px;\n  margin-bottom: 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  user-select: none;\n  color: #6e778b;\n  background: white;\n}\n\n  ._1yYd2jLY > div > * {\n    display: inline-block;\n    vertical-align: middle;\n  }\n@media print {\n._1yYd2jLY {\n    display: none\n}\n  }\na._3RaXoFyV {\n  vertical-align: middle;\n  margin-bottom: 6px;\n}\na._3RaXoFyV img {\n    width: 130.75px;\n    height: 32px;\n    display: block;\n  }\na.-VZjhdEn {\n  text-decoration: none;\n  margin-left: 18px;\n  padding-left: 12px;\n  border-left: 1px solid #6e778b;\n  color: #6e778b;\n  font-size: 12px;\n}\na.-VZjhdEn img {\n    height: 12px;\n    margin-right: 5px;\n  }\na.-VZjhdEn img,\n  a.-VZjhdEn span {\n    display: inline-block;\n    vertical-align: baseline;\n  }\nbutton._2eJsa2Oe {\n  text-decoration: none;\n  font-size: 15px;\n  cursor: pointer;\n  outline: none;\n  padding: 5px;\n}\nbutton._2eJsa2Oe img {\n    height: 14px;\n    margin-right: 6px;\n  }\nbutton._2eJsa2Oe img,\n  button._2eJsa2Oe span {\n    display: inline-block;\n    vertical-align: middle;\n  }\nspan.-AfUrpPx {\n  margin-right: 30px;\n  font-size: 15px;\n  padding: 5px;\n  color: #333;\n  font-weight: 500;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_1yYd2jLY",
	"logo": "_3RaXoFyV",
	"back": "-VZjhdEn",
	"signOut": "_2eJsa2Oe",
	"user": "-AfUrpPx"
};
export default ___CSS_LOADER_EXPORT___;
