import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import cc from 'classcat';
import { VelocityTransitionGroup } from 'velocity-react';
import { Link } from 'react-router-dom';

import history from '@shared/redux/history';

import injectReducer from 'utils/injectReducer';

import { makeSelectLocation } from 'containers/App/selectors';

import reducer from './reducer';
import { updateToast } from './actions';
import makeSelectToaster from './selectors';
import style from './style.scss';

export class Toaster extends PureComponent {
  UNSAFE_componentWillMount() {
    const { time } = this.props.toaster;
    this.timeout = time ? setTimeout(this.clearToaster, time) : null;
  }

  componentDidMount() {
    // If password-reset-redirect in hash, show toast and remove hash
    const { hash, pathname } = this.props.location;
    if (hash === '#password-reset-redirect' && ['/login', '/'].includes(pathname)) {
      this.props.dispatch(
        updateToast(
          'Your password was updated successfully. Please sign in again.',
          '',
          6000,
        ),
      );
      history.replace(pathname);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.toaster.time > 0) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.clearToaster, newProps.toaster.time);
    }
  }

  clearToaster = () => {
    this.props.dispatch(updateToast('', '', 0));
  };

  render() {
    const { message, link, visible } = this.props.toaster;
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <VelocityTransitionGroup
        enter={{ animation: 'slideDown', duration: 150 }}
        leave={{ animation: 'slideUp', duration: 150 }}
      >
        {visible ? (
          <div
            onClick={this.clearToaster}
            className={cc([style.toast, { [style.linkToast]: link }])}
          >
            {link ? (
              <div>
                <div key="1">{message}</div>
                <Link key="2" to={link}>
                  <span>View</span>
                </Link>
              </div>
            ) : (
              <div>{message}</div>
            )}
          </div>
        ) : undefined}
      </VelocityTransitionGroup>
    );
  }
}

Toaster.propTypes = {
  dispatch: PropTypes.func.isRequired,
  toaster: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  toaster: makeSelectToaster(),
  location: makeSelectLocation(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'toaster', reducer });

export default compose(withReducer, withConnect)(Toaster);
