import get from 'lodash/get';
import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the rehealthyTestsPage state domain
 */
const selectRehealthyTestsPageDomain = (state) =>
  get(state, 'rehealthyTestsPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by RehealthyTestsPage
 */

const makeSelectRehealthyTestsPage = () =>
  createSelector(selectRehealthyTestsPageDomain, (substate) => substate);

export const makeSelectRehealthyTestsPageNumber = () =>
  createSelector(selectRehealthyTestsPageDomain, (substate) => get(substate, 'page'));

export const makeSelectRehealthyTestsPagePerPage = () =>
  createSelector(selectRehealthyTestsPageDomain, (substate) => get(substate, 'perPage'));

export const makeSelectRehealthyTestsPageSearchQuery = () =>
  createSelector(selectRehealthyTestsPageDomain, (substate) =>
    get(substate, 'searchQuery'),
  );

export const makeSelectRehealthyTestsPageSearchDates = () =>
  createSelector(selectRehealthyTestsPageDomain, (substate) => ({
    endDate: get(substate, 'endDate'),
    startDate: get(substate, 'startDate'),
  }));

export const makeSelectRehealthyTestsPageSearchHours = () =>
  createSelector(selectRehealthyTestsPageDomain, (substate) =>
    get(substate, 'searchHours'),
  );

export const makeSelectRehealthyTestsPageQualityControl = () =>
  createSelector(selectRehealthyTestsPageDomain, (substate) =>
    get(substate, 'qualityControl'),
  );

export default makeSelectRehealthyTestsPage;
export { selectRehealthyTestsPageDomain };
