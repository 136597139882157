import jwtDecode from 'jwt-decode';

import { getTestsPageHeaders } from 'utils/rehealthy/getTestsPageHeaders';

// NOTE: What happens if there is more than one group? Right now only returning the last one.
const decodeToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return {
      roles: [],
      group: decoded.study,
    };
  } catch (e) {
    return {
      roles: [],
      group: '',
    };
  }
};

export const getFlavors = (token) => {
  let { group, roles } = decodeToken(token); // eslint-disable-line prefer-const
  group = group.toLowerCase();
  const testsPageHeaders = getTestsPageHeaders(group);

  const flavors = {
    studyName: group,
    roles,
    testsPageHeaders,
  };

  return flavors;
};
