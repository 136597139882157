import { hot } from 'react-hot-loader/root';

import AuthProvider from 'containers/AuthProvider';
import AuthSynchronizer from 'containers/AuthProvider/AuthSynchronizer';
import Toaster from 'containers/Toaster';

import style from './style.scss';

function App() {
  return (
    <>
      <AuthProvider />
      <AuthSynchronizer />
      <Toaster className={style.toaster} />
    </>
  );
}

export default hot(App);
