import ReactDOM from '@hot-loader/react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import history from '@shared/redux/history';
import configureStore from '@shared/redux/configureStore';
import { reporter } from '@shared/reporter';

import App from 'containers/App';

// Load the favicon
import './images/favicon.ico';
import './global-styles.scss';

// Preppend [DEV] to title in dev environment
if (process.env.NODE_ENV === 'development')
  document.title = `[${process.env.APP_VARIANT.toUpperCase()}-DEV] ${document.title}`;

// Preppend [LAB] to title in lab environment
if (process.env.NODE_ENV === 'production' && process.env.HEALTHY_ENV === 'lab')
  document.title = `[LAB] ${document.title}`;

// Preppend [STAGING] to title in staging environment
if (process.env.NODE_ENV === 'production' && process.env.HEALTHY_ENV === 'staging')
  document.title = `[STAGING] ${document.title}`;

const store = configureStore();

reporter.init({ dsn: process.env.SENTRY_DSN });

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
);
