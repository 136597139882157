/**
 *
 * LoginForm
 *
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import NewTextInput from 'components/Inputs/NewTextInput';
import Button from 'components/Inputs/Button';

import style from './style.scss';

export class LoginForm extends PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Timeout is necessary to give TextInput time to re-enable. Can't focus on it otherwise.
    /* istanbul ignore else */
    if (!this.props.error && nextProps.error) {
      setTimeout(() => {
        this.usernameInput.input.focus();
      }, 10);
    }
  }

  render() {
    return (
      <div className={style.loginForm}>
        <form onSubmit={this.props.onSubmit}>
          <NewTextInput
            name="username"
            labelText="Email"
            value={this.props.username}
            onChange={this.props.onUsernameChange}
            autoFocus
            width={400}
            maxLength={150}
            disabled={this.props.loading}
            withBorder
            ref={(ti) => {
              this.usernameInput = ti;
            }}
          />
          <NewTextInput
            name="password"
            labelText="Password"
            type="password"
            value={this.props.password}
            onChange={this.props.onPasswordChange}
            width={400}
            maxLength={150}
            disabled={this.props.loading}
            withBorder
          />
          <Button
            text={this.props.loading ? 'Signing In' : 'Sign In'}
            onClick={this.props.onSubmit}
            width={440}
            disabled={Boolean(
              this.props.loading ||
                this.props.password === '' ||
                this.props.username === '',
            )}
            loading={this.props.loading}
            newDesign
            newDesignColor="blue"
            thicker
          />
        </form>
        <div className={style.absolute}>
          <div className={style.error}>
            {this.props.error
              ? 'Wrong username or password. Please try again.'
              : '\u00A0'}
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onUsernameChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default LoginForm;
