import * as Sentry from '@sentry/browser';

const isProd = () =>
  process.env.NODE_ENV === 'production' && process.env.HEALTHY_ENV?.includes('prod');

/**
 * @see
 * [Sentry: Configuration](https://docs.sentry.io/clients/javascript/config/)
 *
 * @param {object} options
 * @param {string} options.dsn - Associated Sentry SDK
 * @param {(Array<string>|Array<RegExp>)} options.ignoreErrors - A list of messages to be filtered out before being sent to Sentry
 */
function init({ dsn, ignoreErrors }) {
  if (isProd() && dsn) {
    Sentry.init({
      dsn,
      ignoreErrors,
      release: `${process.env.APP_VARIANT}-${process.env.APP_VERSION}`,
      environment: process.env.HEALTHY_ENV,
      attachStacktrace: true,
    });
    Sentry.configureScope((scope) => {
      scope.setTag('App name', process.env.APP_VARIANT);
      scope.setExtra('App version', process.env.APP_VERSION);
    });
  }
}

/**
 * Register a set of super properties (tags), which are included with all exceptions.
 *
 * @see
 * [Sentry: Customize Tags](https://docs.sentry.io/platforms/javascript/enriching-events/tags/)
 *
 * @param {object} tags
 */
function setTags(tags) {
  Object.entries(tags).forEach(([key, value]) => {
    Sentry.setTag(key, value);
  });
}

function error(err, additionalMetadata = null) {
  if (isProd()) {
    if (additionalMetadata) {
      Sentry.setExtra('metadata', JSON.stringify(additionalMetadata, null, 2));
    }
    Sentry.captureException(err);
  }
}

const reporter = { init, error, setTags };

export default reporter;
