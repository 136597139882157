import get from 'lodash/get';
import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectAuthDomain = (state) => get(state, 'auth', initialState);

const makeSelectUsername = () =>
  createSelector(selectAuthDomain, (substate) => get(substate, 'username'));

const makeSelectPassword = () =>
  createSelector(selectAuthDomain, (substate) => get(substate, 'password'));

const makeSelectAuthToken = () =>
  createSelector(selectAuthDomain, (substate) => get(substate, 'authToken'));

const makeSelectAuthTokenExpireTime = () =>
  createSelector(selectAuthDomain, (substate) => get(substate, 'authTokenExpireTime'));

/**
 * NOTE: REHEALTHY
 */

const makeSelectFlavorTestsPageHeaders = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'testsPageHeaders']),
  );

const makeSelectFlavorStudyName = () =>
  createSelector(selectAuthDomain, (substate) => get(substate, ['flavors', 'studyName']));
/**
 * NOTE: PARTNERS FLAVORS
 */

const makeSelectFlavorAcrComparison = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'acrComparison']),
  );

const makeSelectFlavorAcrComparisonLab = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'acrComparisonLab']),
  );

const makeSelectFlavorDipComparison = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'dipComparison']),
  );

const makeSelectFlavorAcrResults = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'acrResults']),
  );

const makeSelectFlavorIsIdEditable = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'isIdEditable']),
  );

const makeSelectFlavorAnalytesCount = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'analytesCount']),
  );

const makeSelectFlavorShowNewTestForm = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'showNewTestForm']),
  );

const makeSelectFlavorUseTokenId = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'useTokenId']),
  );

const makeSelectFlavorShouldListenForNewOrders = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'shouldListenForNewOrders']),
  );

const makeSelectFlavorProduct = () =>
  createSelector(selectAuthDomain, (substate) => get(substate, ['flavors', 'product']));

const makeSelectFlavorWithoutFilter = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withoutFilter']),
  );

const makeSelectFlavorWithNextVisit = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withNextVisit']),
  );

const makeSelectFlavorWithRefreshPatientButton = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withRefreshPatientButton']),
  );

const makeSelectFlavorWithBloodPressure = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withBloodPressure']),
  );

const makeSelectFlavorWithLastPeriodDate = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withLastPeriodDate']),
  );

/**
 * NOTE: END2END FLAVORS
 */

const makeSelectFlavorWithPatientData = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withPatientData']),
  );

const makeSelectFlavorWithNhsNumber = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withNhsNumber']),
  );

const makeSelectFlavorWithFulfillmentRequired = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withFulfillmentRequired']),
  );

const makeSelectFlavorWithFulfillmentOptional = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withFulfillmentOptional']),
  );

const makeSelectFlavorName = () =>
  createSelector(selectAuthDomain, (substate) => get(substate, ['flavors', 'name']));

const makeSelectFlavorOldBackend = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'oldBackend']),
  );

const makeSelectFlavorWithCallCenterFeatures = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withCallCenterFeatures']),
  );

const makeSelectFlavorWithAddPatientFlow = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withAddPatientFlow']),
  );

const makeSelectFlavorWithDissentPatientButton = () =>
  createSelector(selectAuthDomain, (substate) =>
    get(substate, ['flavors', 'withDissentPatientButton']),
  );

/**
 * Default selector used by AuthProvider
 */

const makeSelectAuth = () => createSelector(selectAuthDomain, (substate) => substate);

export default makeSelectAuth;
export {
  selectAuthDomain,
  makeSelectUsername,
  makeSelectPassword,
  makeSelectAuthToken,
  makeSelectAuthTokenExpireTime,
  makeSelectFlavorAcrComparison,
  makeSelectFlavorAcrComparisonLab,
  makeSelectFlavorDipComparison,
  makeSelectFlavorAcrResults,
  makeSelectFlavorIsIdEditable,
  makeSelectFlavorAnalytesCount,
  makeSelectFlavorShowNewTestForm,
  makeSelectFlavorUseTokenId,
  makeSelectFlavorShouldListenForNewOrders,
  makeSelectFlavorWithPatientData,
  makeSelectFlavorWithNhsNumber,
  makeSelectFlavorWithFulfillmentRequired,
  makeSelectFlavorWithFulfillmentOptional,
  makeSelectFlavorName,
  makeSelectFlavorOldBackend,
  makeSelectFlavorProduct,
  makeSelectFlavorWithCallCenterFeatures,
  makeSelectFlavorWithAddPatientFlow,
  makeSelectFlavorWithDissentPatientButton,
  makeSelectFlavorTestsPageHeaders,
  makeSelectFlavorStudyName,
  makeSelectFlavorWithoutFilter,
  makeSelectFlavorWithNextVisit,
  makeSelectFlavorWithRefreshPatientButton,
  makeSelectFlavorWithBloodPressure,
  makeSelectFlavorWithLastPeriodDate,
};
