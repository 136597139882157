import Cookies from 'js-cookie';

const domain = window.location.hostname;

Cookies.defaults.expires = 10;

export const getAuthDataFromStorage = () => ({
  authToken: Cookies.get('partnersAuthToken', { domain }) ?? '',
  authTokenExpireTime:
    parseInt(Cookies.get('partnersAuthTokenExpireTime', { domain }), 10) ?? null,
  username: Cookies.get('partnersAuthUsername', { domain }) ?? '',
  idToken: Cookies.get('partnersIDToken', { domain }) ?? '',
});

export const saveAuthDataToStorage = ({
  authToken,
  authTokenExpireTime,
  username,
  idToken,
}) => {
  Cookies.set('partnersAuthToken', authToken, { domain });
  Cookies.set('partnersAuthTokenExpireTime', authTokenExpireTime, { domain });
  Cookies.set('partnersAuthUsername', username, { domain });
  Cookies.set('partnersIDToken', idToken, { domain });
};

export const clearAuthDataFromStorage = () => {
  Cookies.remove('partnersAuthToken', { domain });
  Cookies.remove('partnersAuthTokenExpireTime', { domain });
  Cookies.remove('partnersAuthUsername', { domain });
  Cookies.remove('partnersIDToken', { domain });
};
