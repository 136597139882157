// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.IwiQjxLs button {\n    color: #4c90f5;\n    font-size: 18px;\n    padding: 0 10px;\n    outline: none;\n    cursor: pointer;\n    -webkit-appearance: none;\n            appearance: none;\n    border-radius: 0\n  }\n  div.IwiQjxLs button._2hBVT2F- {\n      color: #333;\n    }\n  div.IwiQjxLs button img {\n      margin-right: 5px;\n      width: 17px;\n    }\n  div.IwiQjxLs button > * {\n      vertical-align: middle;\n    }\n  div.IwiQjxLs button:first-of-type {\n      border-right: 2px solid #c0c0c0;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qctoggle": "IwiQjxLs",
	"inactive": "_2hBVT2F-"
};
export default ___CSS_LOADER_EXPORT___;
