import 'custom-event-polyfill';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import isString from 'lodash/isString';
import Visibility from 'visibilityjs';

import { clearAuthDataFromStorage } from 'utils/authData';

import { updateToast } from 'containers/Toaster/actions';

import { clearAuthData, fetchAuthDataSuccess, invalidateAuthData } from './actions';
import makeSelectAuth from './selectors';

const preParse = (date) => (isString(date) ? parseISO(date) : date);

const requestLogout = new CustomEvent('requestLogout');
export const triggerLogoutEvent = () => {
  // Logout from current tab
  window.dispatchEvent(requestLogout);
  // Share logout event with other tabs
  localStorage.setItem('logoutEvent', '');
  localStorage.removeItem('logoutEvent');
};

export const triggerCrossTabLogin = (authData) => {
  // Share login event with other tabs
  localStorage.setItem('loginEvent', JSON.stringify(authData));
  localStorage.removeItem('loginEvent');
};

export class AuthSynchronizer extends Component {
  UNSAFE_componentWillMount() {
    // Listen to logout requests from current tab
    window.addEventListener('requestLogout', this.handleLogoutAndInvalidateAuthData);
    // Listen to logout/login events from other tabs
    window.addEventListener('storage', this.crossTabAuthEvent);
    // If an expire time is already set, start timeout
    this.expiryTimeout = null;
    this.manageExpiryTimeout(this.props.auth.authTokenExpireTime);

    // Manage expiration on tab visibility change
    Visibility.change(this.handleVisibilityChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newExpireTime = nextProps.auth.authTokenExpireTime;
    if (newExpireTime !== this.props.auth.authTokenExpireTime) {
      this.manageExpiryTimeout(nextProps.auth.authTokenExpireTime);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  // Used instead of handleLocalLogout for when token invalidation is needed
  handleLogoutAndInvalidateAuthData = ({ sessionTimeout = false }) => {
    // Invalidate auth data only from the current tab
    this.props.dispatch(invalidateAuthData());
    // Logout from current tab
    this.handleLocalLogout({ sessionTimeout });
  };

  // If signed in, calculate the time until expiry and set a timeout to trigger a sign out (for all tabs) when that time comes
  manageExpiryTimeout(authTokenExpireTime) {
    // Always start by clearing the timeout
    clearTimeout(this.expiryTimeout);
    const now = new Date();

    // If null/not set, nothing to do
    if (authTokenExpireTime === null) return;

    // Next, check that the auth token isn't already expired
    if (isAfter(preParse(authTokenExpireTime), now)) {
      // If still valid, set the timeout
      const timeLeft = differenceInMilliseconds(authTokenExpireTime, now);
      /* istanbul ignore next */
      this.expiryTimeout = setTimeout(
        () => this.handleLogoutAndInvalidateAuthData({ sessionTimeout: true }),
        timeLeft,
      );
    } else if (isBefore(preParse(authTokenExpireTime), now)) {
      // If already invalid, sign out
      this.handleLocalLogout({ sessionTimeout: true });
    }
  }

  // This is only triggered in inactive browser tabs where the app is open
  crossTabAuthEvent = (e) => {
    // Checking for e.newValue to filter out the second event (triggered by localStorage.removeItem)
    if (e.newValue === null) return;
    switch (e.key) {
      case 'loginEvent': {
        const authData = JSON.parse(e.newValue);
        this.props.dispatch(
          fetchAuthDataSuccess(
            authData.authToken,
            authData.authTokenExpireTime,
            authData.username,
            authData.idToken,
          ),
        );
        break;
      }
      case 'logoutEvent': {
        this.handleLocalLogout({});
        break;
      }
      default: {
        break;
      }
    }
  };

  handleVisibilityChange = (e, state) => {
    if (state === 'visible')
      this.manageExpiryTimeout(this.props.auth.authTokenExpireTime);
  };

  // Note: All logouts go through here
  handleLocalLogout = ({ sessionTimeout = false }) => {
    // Clear auth data from both storage and redux store
    clearAuthDataFromStorage();
    this.props.dispatch(clearAuthData());

    if (sessionTimeout) {
      this.props.dispatch(
        updateToast('Your session has expired. Please sign in again.', '', 6000),
      );
    }
  };

  render() {
    return null;
  }
}

AuthSynchronizer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthSynchronizer);
