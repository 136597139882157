/*
 *
 * Button
 *
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { Link } from 'react-router-dom';

import style from '../style.scss';

// Using a class so that refs can work wherever Button is used
export class Button extends PureComponent {
  render() {
    const {
      onClick,
      width,
      disabled,
      loading,
      text,
      newDesign,
      newDesignColor,
      extraClass,
      thicker,
      anchorIsBetter,
      href,
      type,
    } = this.props;
    const classes = cc([
      style.button,
      extraClass,
      {
        [style.newDesign]: newDesign,
        [style.newDesignBlue]: newDesignColor === 'blue',
        [style.newDesignWhite]: newDesignColor === 'white',
        [style.thicker]: newDesign && thicker,
      },
    ]);

    // NOTE: Anchor mode doesn't reflect 'disabled', 'type' or 'loading' props
    if (anchorIsBetter)
      return (
        <Link to={href} className={classes} style={width ? { width } : null}>
          {text}
        </Link>
      );

    /* eslint-disable react/button-has-type */

    return (
      <button
        type={type || 'submit'}
        className={classes}
        onClick={onClick}
        style={width ? { width } : null}
        disabled={disabled}
        ref={(button) => {
          this.button = button;
        }}
      >
        {loading ? (
          [<span key="loader" className={style.loader} />, <span key="text">{text}</span>]
        ) : (
          // Keep the span even when loading === false for height preservation
          <span>{text}</span>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  width: PropTypes.number,
  extraClass: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  newDesign: PropTypes.bool,
  newDesignColor: PropTypes.oneOf(['blue', 'white']),
  thicker: PropTypes.bool,

  anchorIsBetter: PropTypes.bool,
  href: PropTypes.string,
};

export default Button;
